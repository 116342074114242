<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Permohonan Surat Masuk",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "SURAT MASUK",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Masuk",
          href: "/surat-masuk",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      showLampiran: false,
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      sifat_surat: ["Biasa", "Rahasia", "Sangat Rahasia"],
      kategori_surat: [],
      bentuk_dokumen: [
        "Surat",
        "Surat dan Proposal",
        "Surat dan Pendukung Lainnya",
      ],
      total_draft: "",
      total_inbox: "",
    
      // saved variable
      id: this.$route.params.id,

      // data detail
      ad_bentukdokumen: "",
      ad_departemen: "",
      ad_dikirim: "",
      ad_divisi: "",
      ad_duedate: "",
      ad_file_tindaklanjut: "",
      ad_id: "",
      ad_id_teamleader: "",
      ad_instansipengirim: "",
      ad_is_booking: "",
      ad_kategorisurat: "",
      ad_kategorisurat_code: "",
      ad_kategorisurat_id: "",
      ad_lampiran: "",
      ad_lampiran_password: "",
      ad_nomorsurat: "",
      ad_notelp: "",
      ad_perihal: "",
      ad_pic: "",
      ad_sifatsurat: "",
      ad_signer: "",
      ad_tandatangan: "",
      ad_tanggalsurat: "",
      ad_tanggalsuratditerima: "",
      ad_tindaklanjut: "",
      ad_tipesurat: "",
      ad_tsk_id: "",
      created_at: "",
      created_by: "",
      dari: "",
      kirim_date: "",
      status: "",
      status_message: "",
      updated_at: "",
      updated_by: "",
      timeline: [],
      barcode_link: '',
      //lampiran
      lampiran: [],
      lampiran_db: "",
      modalTeruskan: false,
      table_data: [],
      search: "",
      loadingTableUser: true,
      selected: [],
      isi_disposisi: "",
      user_kode: "",
      modalShowPassword: false,
      showPasswordSurat: false,
      kategori_surat_selected: "",
      //lampiran
      draft: "y"
    };
  },
  mounted() {
    this.getSurat();
    this.getKategori();
    this.getSuratMasuk();
    this.getLampiran();
  },
  methods: {
    getSuratMasuk() {
      let self = this;
      self.loadingTable = true;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "api/arsip-dokumen/suratmasuk/detail?i=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          //console.log(response_data)
          if (response_data.code == 200) {
            var arsip_dokumen = response_data.data.arsip_dokumen;
            self.ad_nomorsurat = arsip_dokumen.ad_nomorsurat;
            self.ad_tanggalsurat = arsip_dokumen.ad_tanggalsurat;
            self.ad_tanggalsuratditerima = arsip_dokumen.ad_tanggalsuratditerima;
            self.ad_tipesurat = arsip_dokumen.ad_tipesurat;
            self.ad_instansipengirim = arsip_dokumen.ad_instansipengirim;
            self.ad_sifatsurat = arsip_dokumen.ad_sifatsurat;
            self.ad_bentukdokumen = arsip_dokumen.ad_bentukdokumen;
            self.ad_kategorisurat = arsip_dokumen.ad_kategorisurat;
            self.ad_perihal = arsip_dokumen.ad_perihal;
            self.ad_duedate = arsip_dokumen.ad_duedate;
            self.ad_file_tindaklanjut = arsip_dokumen.ad_file_tindaklanjut;
            self.ad_lampiran = arsip_dokumen.ad_lampiran;
            self.ad_lampiran_password = arsip_dokumen.ad_lampiran_password;
            self.status = arsip_dokumen.status;
            self.status_message = arsip_dokumen.status_message;
            self.total_draft = response_data.count_draft;

            // Kategori Surat
            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                  "/api/master/departemen?status=ENABLE&id="+arsip_dokumen.ad_kategorisurat_id
              )
              .then((response) => {
                var response_data = response.data;
                if (response_data.code == 200) {
                  self.kategori_surat_selected = response_data.list_data.data[0];
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                  });
                }
              });
            
            if(self.ad_bentukdokumen != 'surat'){
              self.showLampiran = true;
            }
            self.loadingTable = false;
            Swal.close();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Gagal Load Data",
            });
          }
        });
    },
    getLampiran() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/arsip-dokumen/lampiran?id=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
              let clear_data_json_lampiran = [];
              $.each(response_data.list_data, function (indexInArray, valueOfElement) { 
                clear_data_json_lampiran.push({
                  'file': valueOfElement.path,
                  'keterangan': valueOfElement.keterangan,
                  'password': valueOfElement.password,
                })
              });
              self.lampiran = clear_data_json_lampiran;
              self.lampiran_db = self.lampiran.length;
              self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getSurat() {
      let self = this;
      self.loadingTable = true;

      var kategori_id = self.kategori_selected?.id;
      if(kategori_id){
        kategori_id = self.kategori_selected?.id;
      }else{
        kategori_id = '';
      }

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/arsip-dokumen/suratmasuk"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.total_inbox = response_data.count_inbox;
            self.total_draft = response_data.count_draft;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getKategori() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/departemen?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.kategori_surat = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    uploadFile() {
      let self = this;
      if ($("#upload")[0].files[0]) {
        if ($("#upload")[0].files[0].size < 30000000) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload")[0].files[0]);
          data.append("q", '');
          data.append("e", 'yes');
          data.append("t", 'masuk');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response)
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.ad_lampiran = urlres;
            self.ad_lampiran_password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
      
    },
    StoreData() {
        let self = this;

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        var FormData = require("form-data");
        var data = new FormData();
        data.append("ad_id", self.id);
        data.append("ad_perihal", self.ad_perihal);
        data.append("draft", self.draft);
        var config = {
            method: "post",
            url:
            process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratmasuk/storeperihal",
            data: data,
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data; 
            //console.log(response);
            if(response_data.code != 200){
                Swal.fire({
                icon: "error",
                title: "Oops...",
                html: response_data.message,
                });
            }else{
                let timerInterval;
                Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Anda akan diarahkan ke halaman arsip dokumen surat masuk segera",
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    // self.$router.push({ name: "vendor" });
                    Swal.close();
                    self.showModal = false;
                    self.last_inserted_id = response_data.id;
                    self.$router.push({ name: "all-surat_masuk", params: { token: self.last_inserted_id } });
                }
                });
            }
            })
            .catch((e) => {
            this.axiosCatchError = e.response.data.data;
            Swal.close();
        });
    },

    draftClick(){
      let self = this;
      self.draft = 'y';
      $("#submit-button").trigger('click');
    },
    
    kirimClick(){
      let self = this;
      self.draft = 'n';
      $("#submit-button").trigger('click');
    },

    // penugasan
    addlampiran() {
      this.lampiran.push({
        file: '',
        keterangan: '',
        kode: '',
      });
    },
    removelampiran(index) {
      this.lampiran.splice(index, 1);
    },

    uploadFileLampiran(row) {
      let self = this;
      if ($("#upload_file_lampiran"+row+"")[0].files[0]) {
        if ($("#upload_file_lampiran"+row+"")[0].files[0].size < 30000000) {
          $("#upload_loading_file_lampiran"+row+"").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_file_lampiran"+row+"")[0].files[0]);
          data.append("q", '');
          data.append("e", 'yes');
          data.append("t", 'masuk');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response);
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#upload_loading_file_lampiran"+row+"").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.lampiran[row].file = urlres;
            self.lampiran[row].password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
    },

    bentukDokumenSelected(){
      let self = this;
      if(self.ad_bentukdokumen != 'surat'){
        self.showLampiran = true;
      }else{
        self.showLampiran = false;
      }
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += self.ad_lampiran;
      link.href = urlres;
      link.click();
    },

    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="box box-solid">
          <div class="box-body no-padding">
            <div class="row">
              <div class="col-md-3 text-center btn-custom-1">
                <router-link :to="{ name: 'surat-masuk-draft' }" class="btn btn-custom-1 navbar-brand text-dark" href="#">
                  <img src="@/assets/images/Draft.png" width="25" />
                  Draft
                  <span class="badge bg-primary">{{ total_draft }}</span>
                </router-link>
              </div>
              <div class="col-md-3 text-center btn-custom-1">
                <router-link :to="{ name: 'surat-masuk-diajukan' }" class="btn btn-custom-1 navbar-brand text-dark w-full"
                  href="#">
                  <img src="@/assets/images/menunggu-disposisi.png" width="25" />
                  Menunggu Disposisi
                </router-link>
              </div>
              <div class="col-md-3 text-center btn-custom-1">
                <router-link :to="{ name: 'surat-masuk-didisposisikan' }" class="btn btn-custom-1 navbar-brand text-dark"
                  href="#">
                  <img src="@/assets/images/Sudah_Disposisi.png" width="25" />
                  Sudah Didisposisi
                </router-link>
              </div>
              <div class="col-md-3 text-center btn-custom-1">
                <router-link :to="{ name: 'surat-masuk-ditindaklanjuti' }" class="btn btn-custom-1 navbar-brand text-dark"
                  href="#">
                  <img src="@/assets/images/Sudah_Ditindaklanjuti.png" width="20" />
                  Sudah Ditindaklanjuti
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form class="p-2" @submit.prevent="StoreData">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group class="mb-3">
                    <label>Nomor Surat <span class="text-danger">* Wajib diisi</span></label>
                    <b-form-input type="text" v-model="ad_nomorsurat" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Tanggal Surat Diterima">
                    <b-form-input type="date" v-model="ad_tanggalsuratditerima" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Sifat Surat">
                    <select class="form-control input-sm" v-model="ad_sifatsurat" readonly>
                      <option value="">-Pilih Sifat Surat-</option>
                      <option value="biasa">Biasa</option>
                      <option value="rahasia">Rahasia</option>
                      <option value="sangat_rahasia">
                        Sangat Rahasia
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Kategori Surat <span class="text-danger">* Wajib diisi</span></label>
                    <v-select
                      placeholder="-Pilih Kategori Surat-"
                      :options="kategori_surat"
                      label="nama"
                      v-model="kategori_surat_selected"
                      disabled
                    ></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Perihal Surat">
                    <textarea v-model="ad_perihal" class="form-control" cols="30" rows="10"></textarea>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Attachment">
                    <div class="respond-input-file float-left" id="uploadLoading">
                      <div class="btn btn-info btn-sm mt-1" @click="downloadHandle()">
                        <i class="fa fa-download"></i> Download
                      </div>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-3" label="Tanggal Surat">
                    <b-form-input type="date" v-model="ad_tanggalsurat" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Instansi Pengirim">
                    <b-form-input type="text" v-model="ad_instansipengirim" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Bentuk Dokumen">
                    <select class="form-control input-sm" v-model="ad_bentukdokumen" @update:modelValue="bentukDokumenSelected" disabled>
                      <option value="">-Pilih Bentuk Dokumen-</option>
                      <option value="surat">Surat</option>
                      <option value="surat_dan_proposal">
                        Surat dan Proposal
                      </option>
                      <option value="surat_dan_dokumen_pendukung_lainnya">
                        Surat dan Pendukung Lainnya
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Due Date">
                    <b-form-input type="date" v-model="ad_duedate" readonly></b-form-input>
                  </b-form-group>
                  <input type="hidden" class="form-control" v-model="draft">
                </div>
              </div>
              <div class="row" v-if="showLampiran">
                <div class="col-md-12">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover w-full mb-2"
                  >
                    <thead>
                      <tr class="bg-light">
                        <th colspan="4">Lampiran</th>
                      </tr>
                      <tr class="bg-light">
                        <th>#</th>
                        <th>File</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="lampiran.length == 0">
                        <td colspan="4">TIdak Ada Data</td>
                      </tr>
                      <tr v-for="(item, index) in lampiran" :key="index">
                        <td>#</td>
                        <td>
                          <input type="hidden" v-model="item.file">
                          <div v-if="lampiran_db >= index + 1">
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran'+index">
                              <div class="btn btn-info btn-sm mt-1" :id="'file_lampiran'+index" @click="downloadEachAttachment(item.file)"><i class="fa fa-eye"></i> Lihat File</div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran'+index"></div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="item.keterangan"
                            readonly
                          />
                          <input
                            type="hidden"
                            class="form-control"
                            v-model="item.kode"
                            readonly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <router-link
                    :to="{ name: 'all-surat_masuk' }"
                    class="btn btn-secondary btn-sm text-white m-1"
                  >
                    <i class="fa fa-chevron-left"></i> Kembali Ke List Surat
                  </router-link>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <div v-if="ad_lampiran" class="btn btn-primary m-1"  v-on:click="kirimClick">
                      <i class="fa fa-save"></i> Kirim
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" style="display:none" id="submit-button">Submit</button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
